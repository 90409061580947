<template>
    <div class="user-detail mt-8 md:mt-0">
        <div class="buttons flex flex-row justify-around md:justify-between">
            <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
            <div v-if="user && user.id">
                <div class="inline-block mr-4">
                    <span>Suspendu</span>
                    <Toggle
                    :disabled="userInfo.id == user.id || isReadOnly || hasPendingStoreRequest"
                    :checked="!user.disabled"
                    :onInput="() => { user.disabled = !user.disabled }" class="mx-4" />
                    <span>Actif</span>
                </div>
                <button v-if="!isReadOnly" type="button" class="twn-button text-xs danger mr-4" :disabled="hasPendingStoreRequest || userInfo.id == user.id" @click="showDeleteModal">Supprimer le profil</button>
                <button v-if="!isReadOnly" type="button" class="twn-button text-xs" :disabled="hasPendingStoreRequest" @click="save">Enregistrer le profil</button>
            </div>
            <div v-else-if="!isReadOnly">
                <button v-if="user && user.role == 'user'" type="submit" class="twn-button text-sm" :disabled="hasPendingStoreRequest" @click="save">Ajouter</button>
                <button v-else type="submit" class="twn-button text-sm" :disabled="hasPendingStoreRequest" @click="save">Ajouter et envoyer l'email de confirmation</button>
            </div>
        </div>
        <div class="flex flex-col" v-if="user">
            <div class="twn-card user-card my-10">
                <div class="flex justify-between items-center">
                    <div class="uppercase font-principal-bold text-sm" v-if="!user.id">Ajouter un profil</div>
                    <div class="uppercase font-principal-bold text-sm" v-else>éditer le profil</div>

                    <template v-if="!isReadOnly && user.id">
                        <button
                            v-if="user.cognito_id"
                            type="button"
                            class="twn-button text-xs"
                            :disabled="hasPendingStoreRequest"
                            @click="showResetPasswordModal"
                        >
                            Réinitialiser le mot de passe
                        </button>
                        <button
                            v-else
                            type="button"
                            class="twn-button text-xs"
                            :disabled="hasPendingStoreRequest"
                            @click="showRegisterNowModal"
                        >
                            Envoyer l'email d'invitation maintenant
                        </button>
                    </template>
                </div>
                <hr>
                <form class="flex flex-row flex-wrap justify-around" @submit="save">
                    <div class="left">
                        <div class="input-group mt-0 md:mt-10">
                            <label for="email">Adresse e-mail</label>
                            <b-form-input v-model="user.email" type="email" id="email" :state="emailValidation"
                            :readonly="(user.id || isReadOnly ? true : false)" :disabled="(user.id || isReadOnly ? true : false)"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="first_name">Prénom</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.first_name" type="text" id="first_name"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="name">Nom</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.name" type="text" id="name" :state="nameValidation"></b-form-input>
                        </div>
                        <div class="input-group" v-if="isSuperAdmin">
                            <label for="role">Rôle</label>
                            <v-select
                                v-model="user.role"
                                id="role"
                                :options="roleList"
                                label="name"
                                class="twn-select"
                                :reduce="role => role.slug"
                                :clearable="false"
                                :disabled="isReadOnly"
                            >
                            </v-select>
                        </div>

                        <!-- <div class="input-group">
                            <label for="gender">Genre</label>
                            <v-select
                                v-model="user.gender"
                                id="gender"
                                :options="userFieldsValues.gender"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="age">Age</label>
                            <v-select
                                v-model="user.age"
                                id="age"
                                :options="userFieldsValues.age"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.is_disabled_worker" id="is_disabled_worker">
                                Travailleur(se) handicapé(e)
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="level_studies">Niveau d'éducation</label>
                            <v-select
                                v-model="user.level_studies"
                                id="level_studies"
                                :options="userFieldsValues.level_studies"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="actual_position">Poste</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.actual_position" type="text" id="actual_position"></b-form-input>
                        </div> -->
                    </div>
                    <div class="right">
                        <div class="input-group">
                            <label for="user-group">Promotion</label>
                            <v-select
                            id="user-group"
                            class="twn-select"
                            placeholder="Rechercher une promotion..."
                            :options="groupList"
                            :reduce="group => group.id"
                            :getOptionLabel="getGroupLabel"
                            v-model="group"
                            :clearable="!isUserManager"
                            :disabled="isReadOnly" />

                            <router-link
                                v-if="group"
                                class="twn-button text-xs small ml-2 flex-shrink-0"
                                :to="{
                                    name: 'promotions-edit',
                                    params: { itemID: group }
                                }"
                                target="_blank"
                            >
                               Voir la promotion
                           </router-link>
                        </div>
                        <!-- <div class="input-group">
                            <label for="actual_working_time">Temps de travail</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.actual_working_time" type="text" id="actual_working_time"></b-form-input>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.rise_working_time" id="rise_working_time">
                                Souhaite un temps augmenté de travail
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="contract">Contrat</label>
                            <v-select
                                v-model="user.contract"
                                id="contract"
                                :options="userFieldsValues.contract"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="experiences">Années d'expériences</label>
                            <v-select
                                v-model="user.experiences"
                                id="experiences"
                                :options="userFieldsValues.experiences"
                                class="twn-select"
                                :reduce="data => data.value"
                                :clearable="false"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.has_professional_courses" id="has_professional_courses">
                                A déjà suivi une formation professionnelles
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="professional_courses">Formation(s) suivie(s)</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.professional_courses" type="text" id="professional_courses"></b-form-input>
                        </div>
                        <div class="input-group">
                            <b-form-checkbox v-model="user.has_distance_learning" id="has_distance_learning">
                                A déjà suivi une formation en elearning
                            </b-form-checkbox>
                        </div>
                        <div class="input-group">
                            <label for="expectations">Attente(s)</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.expectations" type="text" id="expectations"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="additionnal_needs">Autres besoins</label>
                            <b-form-input :disabled="isReadOnly" v-model="user.additionnal_needs" type="text" id="additionnal_needs"></b-form-input>
                        </div> -->
                    </div>
                    <button
                        v-if="user && user.id"
                        type="button"
                        class="twn-button text-xs"
                        :disabled="isLoadingCertificate"
                        @click="onDownloadCertificateClick"
                    >
                        Télécharger l'attestation de réalisation
                    </button>
                </form>
            </div>

            <UserDashboard v-if="!hasPendingStoreRequest && user && user.id" :id="user.id" />
        </div>

        <b-modal ref="delete-modal-detail-user" class="bootstrap" centered hide-footer id="delete-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-user')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>

        <b-modal ref="register-now-modal-detail-user" class="bootstrap" centered hide-footer id="register-now-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>
                    Etes-vous sûr de vouloir envoyer l'email d'invitation maintenant ?
                </h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('register-now-modal-detail-user')">Annuler</button>
                <button type="button" class="mt-4 twn-button danger" @click="registerNow">Envoyer</button>
            </div>
        </b-modal>

        <b-modal ref="reset-password-modal-detail-user" class="bootstrap" centered hide-footer id="reset-password-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>
                    Etes-vous sûr de vouloir réinitialiser le mot de passe de cette utilisateur ?
                </h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('reset-password-modal-detail-user')">Annuler</button>
                <button type="button" class="mt-4 twn-button danger" @click="resetPassword">Réinitialiser</button>
            </div>
        </b-modal>
    </div>
</template>
<script>

import moment from 'moment'

import { mapState } from 'vuex';

import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"

import UserDashboard from '@/components/dashboard/User'

import userFieldsValues from "@/constants/userFieldsValues"

import Toggle from '@/components/Toggle'

export default {
    name: 'UserDetail',
    mixins: [ dispatchStoreRequest ],
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
    components:{
        Toggle,
        UserDashboard
    },
    data() {
        return {
            isLoadingCertificate: false,
            remoteUserData: null,
            user: null,
            isUserValidated: true,
            userFieldsValues,
            promotionArrFields:[
				{
					key: "index",
					label: "",
					sortable: false
				},
				{
					key: "title",
					label: "Promotions associées",
					sortable: false
                },
				{
					key: "start_date",
					label: "Date de début",
					sortable: false
                },
				{
					key: "end_date",
					label: "Date de fin",
					sortable: false
				},
			],
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        ...mapState('Utils', [ 'organisationNameList', 'statusNameList', 'userList' ]),
        ...mapState({
            groupList: state => state.Promotion.list,
            metaTypes: state => {
                return state.Utils.metaTypeList.reduce((dict, meta) => {
                    dict[meta.slug] = meta
                    return dict
                }, {})
            }
        }),
        group: {
            get() {
                return (this.user.groups && this.user.groups.length > 0 ? this.user.groups[0].id : null)
            },
            set(value) {
                if (value == null) {
                    this.user.groups = []
                } else if (this.user.groups && this.user.groups.length > 0) {
                    this.user.groups[0].id = value
                } else {
                    this.user.groups = [{ id: value }]
                }
            }
        },
        isSuperAdmin() {
            return (this.userInfo && this.userInfo.role == 'superadmin')
        },
        isUserAdmin() {
            return (this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'customer_manager'))
        },
        isUserManager() {
            return (this.userInfo && this.userInfo.role == 'customer_manager')
        },
        isReadOnly() {
            return !this.isSuperAdmin && !this.isUserAdmin
        },
        nameValidation(){
            return this.user.name !== undefined && this.user.name !== null && this.user.name !== '';
        },
        emailValidation(){
            const valid = (this.user.email !== undefined && this.user.email !== null && this.user.email !== '')

            // Check if email exist when creating an user
            if (!this.user || !this.user.id) {
                return valid && !this.userList.find((user) => {
                    return (user.email == this.user.email)
                })
            }

            return valid
        },
        roleList() {
            let list = [
                { slug: 'user', name: 'Utilisateur'},
            ]

            if (this.isSuperAdmin) {
                list.push({ slug: 'customer_manager', name: 'Responsable client'})
                list.push({ slug: 'customer_supervisor', name: 'Superviseur client'})
                list.push({ slug: 'superadmin', name: 'SuperAdmin'})
            }

            return list
        },
        finishedAllCourseDate() {
            if (!this.user || !this.user.logs) {
                return null
            }

            const courseDone = {}
            let lastCourseDoneDate = null

            this.user.logs.forEach((log) => {
                if (log.type.slug === 'course') {
                    const doneDate = new Date(log.created_at)
                    
                    courseDone[log.data_key] = log


                    if (!lastCourseDoneDate || lastCourseDoneDate.getTime() < doneDate.getTime()) {
                        lastCourseDoneDate = doneDate
                    }
                }
            })

            if (Object.keys(courseDone).length > 0) {
                return lastCourseDoneDate
            }

            return null
        },
        managedCustomer() {
            if (!this.isUserManager) {
                return null
            }

            return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
        },
    },
    async mounted() {
        await this.dispatchStoreRequest('Promotion/getList')
    },
    watch: {
        itemID: {
            async handler(id) {
                // Load current user data, if needed
                if (id) {
                    await this.loadData()

                    // todo: common/cleaner system
                    document.title = process.env.VUE_APP_PAGE_TITLE + ' - ' + [this.user.name, this.user.first_name].join(' - ')
                } else {
                    // Get default object without cache
                    this.user = await this.dispatchStoreRequest('Users/getDefault', null, true)
                    this.remoteUserData = await this.dispatchStoreRequest('Users/getDefault', null, true)
                }
            },
            immediate: true
        }
    },
    methods: {
        async loadData() {
            // Get user from store
            // todo: handle invalid uuid response
            await this.dispatchStoreRequest("Users/getByID", this.itemID, true)

            // Create a deep local copy of the store data (todo: really needed ?)
            this.remoteUserData = this.$store.state.Users.items[this.itemID]
            this.user = JSON.parse(JSON.stringify(this.remoteUserData))
        },
        goBack() {
            this.$router.push({
                name: 'users-list',
                params: {
                    previousPage: this.previousPage
                }
            })
        },
        showDeleteModal() {
            this.$refs['delete-modal-detail-user'].show()
        },
        async deleteItem() {
            if (this.hasPendingStoreRequest)
                return

            const user = {...this.user}
            this.user = null

            await this.dispatchStoreRequest('Users/delete', user)

            this.$refs['delete-modal-detail-user'].hide()
            this.$router.push({ name: 'users-list' })
        },
        async save(e) {
            e.preventDefault()
            
            if (!this.nameValidation || !this.emailValidation || this.hasPendingStoreRequest)
                return

            if (this.isUserManager && this.managedCustomer) {
                // Check quota
                const users = (this.$store.state.Users.list.length && this.$store.state.Users.list) || await this.dispatchStoreRequest('Users/getList')
                const simpleUsers = users.filter((user) => {
                    return (user.role === 'user')
                })

                if (simpleUsers.length >= this.managedCustomer.quota) {
                    alert('Impossible d\'ajouter un nouvelle utilisateur, vous n\'avez plus d\'invitation disponible. Contactez l\'AFPOLS pour en obtenir de nouvelles.')
                    return
                }

                // Force group to be set, otherwise the user will not appear in the "customer" user list
                if (this.user.groups.length <= 0) {
                    alert('L\'utilisateur doit faire partie d\'une promotion.')
                    return
                }
            }

            // Cleanup data
            delete this.user.content_logs
            delete this.user.lastLoginLog
            delete this.user.logs

            const response = await this.dispatchStoreRequest('Users/save', {
                user: this.user,
            })

            if (response.error) {
                this.$bvToast.toast('Erreur de création de l\'utilisateur.', { title: `Erreur` })
                return
            } else if (response.id) {
                this.$router.push({
                    name: 'user-edit',
                    params: {
                        itemID: response.id,
                    }
                })
            } else {
                // Refresh user data
                await this.loadData()
            }

            this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
        },
        showRegisterNowModal() {
            if (this.user.cognito_id)
                return

            this.$refs['register-now-modal-detail-user'].show()
        },
        async registerNow() {
            this.$refs['register-now-modal-detail-user'].hide()

            if (this.user.cognito_id)
                return

            // Get group data used later for user log
            const groupId = this.remoteUserData.groups?.[0]?.id
            const group = this.groupList.find((group) => group.id === groupId)

            if (this.user.role == 'user' && !group) {
                alert('L\'utilisateur doit être dans une promotion')
                return
            }

            try {
                await this.dispatchStoreRequest('Users/registerExistingUser', {
                    userId: this.user.id,
                    email: this.user.email
                })//todo: email check ? real email ?

                let mailTemplate = 'access-account'

                if (group) {
                    // Add mail log
                    const now = new Date()
                    const startDate = new Date(group.start_date)

                    mailTemplate = 'access-before'
                    
                    if (startDate.getTime() < now.getTime()) {
                        mailTemplate = 'access-late'
                    }
                }

                await this.dispatchStoreRequest('Logs/addUserLog', {
                    userId: this.user.id,
                    id: groupId,
                    data: { type: mailTemplate },
                    type: 'mail',
                })

                // Refresh user data
                await this.loadData()

                alert('L\'email d\'invitation à été envoyé')
            } catch (error) {
                alert(error.message)
            }
        },
        showResetPasswordModal() {
            if (!this.user.cognito_id)
                return

            this.$refs['reset-password-modal-detail-user'].show()
        },
        async resetPassword() {
            this.$refs['reset-password-modal-detail-user'].hide()

            if (!this.user.cognito_id)
                return

            try {
                await this.dispatchStoreRequest('Users/resetPassword', this.user.email)//todo: email check ? real email ?
                alert('Le mot de passe à été réinitialisé')
            } catch (error) {
                alert(error.message)
            }
        },
        getMeta(slug) {
            if (!this.metaTypes[slug])
                return { meta: { value: '~ Meta value error ~' }, index: -1 }

            for (var i = 0; i < this.user.metas.length; i++) {
                if (this.user.metas[i].meta_type_id == this.metaTypes[slug].id) {
                    return { meta: this.user.metas[i], index: i }
                }
            }
            
            return { meta: null, index: -1 }
        },
        getMetaValue(slug, defaultValue = null) {
            const { meta } = this.getMeta(slug)

            return ((meta && meta.value) || defaultValue)
        },
        setMetaValue(slug, value) {
            // Try to find existing meta with this slug
            let { meta, index } = this.getMeta(slug)

            // Update, add or remove meta value
            if (meta) {
                if (value != null) {
                    meta.value = value
                } else {
                    this.user.metas.splice(index, 1)
                }
            } else if (value) {
                this.user.metas.push({
                    value,
                    meta_type_id: this.metaTypes[slug].id
                })
            }
        },
        getGroupLabel(option) {
            if (!option)
                return 'Promotion supprimé'

            const startDate = this.formatDate(option.start_date)
            const endDate = this.formatDate(option.end_date)

            return [option.identifier, option.name, option.customer && option.customer.name, startDate, endDate].filter(str => str || false).join(' - ') || '~ Promotion sans titre ou supprimé ~'
        },
        formatDate(date){
            return moment(new Date(date)).locale('fr').format('L')
        },
        async onDownloadCertificateClick() {
            if (this.isLoadingCertificate) {
                return
            }

            this.isLoadingCertificate = true

            if (this.user) {
                const lastLoginDate = new Date(this.user.lastLoginLog?.[0]?.created_at || 0)

                const { downloadCertificateForUser } = await import ('@/utils/pdfCertificate')

                await downloadCertificateForUser(this.user, (this.finishedAllCourseDate || lastLoginDate))
            }

            this.isLoadingCertificate = false
        }
    },
}
</script>
<style lang="scss">
.user-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/3 text-xs;
            }
            > input{
                &:not(.is-invalid){
                    @apply border-line-top;
                }
                @apply w-2/3 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-2/3;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
        }
    }
    @screen md {
        .left, .right {
            > div {
                @apply my-10;
            }
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
}
</style>